.main-news {
	padding: 50px 0;
	.container {
		position: relative; }
	.main-news__slider {
		margin: 0 10px; }
	&__button {
		text-align: center; }
	&__title {
		margin-bottom: 20px;
		line-height: 1.7;
		@media screen and (max-width: $phone-small) {
			text-align: center; } }
	&__content {
		position: absolute;
		background: $black-bg;
		color: $white;
		left: 0;
		bottom: 0;
		padding: 20px;
		border-radius: 0;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		&-title {
			text-decoration: none;
			display: inline-block;
			line-height: 1.6;
			font-size: 16px;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			&:hover {
				color: $white; } }
		&-date {
			font-size: 14px;
			display: block; }
		&-more {
			display: block;
			color: darken($white, 15);
			@include mt(.3s);
			font-size: 14px;
			&:after {
				content: '\2192';
				margin-left: 8px; }
			&:hover {
				color: $white;
				transform: translateX(15px); } } }
	&__item {
		position: relative;
		overflow: hidden;
		outline: 0;
		margin: 0 10px 20px; }
	&__img {
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		width: 100%;
		height: 280px; } }
.news-page {
	h1 {
		font-size: 25px;
		text-align: center;
		margin-bottom: 30px; }
	.news {
		margin: 0 0 50px;
		.container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-around;
			.main-news__item {
				width: 30%;
				@media screen and (max-width: 900px) {
					width: 45%; }
				@media screen and (max-width: 576px) {
					width: 100%; } } }
		.icon-mode {
			fill: #333;
			width: 16px;
			height: 16px;
			position: absolute;
			left: 0;
			top: 10px; }
		&__title {
			font-size: 25px;
			margin-bottom: 20px; }
		&__date {
			border-top: 1px solid #eee;
			border-bottom: 1px solid #eee;
			padding: 10px 0;
			margin-bottom: 20px;
			position: relative;
			padding-left: 25px;
			color: #666; }
		&__img {
			margin-bottom: 20px;
			max-width: 100%; }
		&__text {
			color: #333;
			line-height: 1.5;
			text-indent: 30px;
			text-align: justify; } } }
