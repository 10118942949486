.testimonials {
	text-align: center;
	background: $white;
	background-image: url(../images/general/testimonials-bg.jpg);
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	background-attachment: fixed;
	padding-bottom: 50px;
	.container {
		position: relative;
		z-index: 5; }
	&__title {
		margin-bottom: 20px; }
	&__content {
		text-align: center;
		&-name {
			font-size: 20px;
			line-height: 1.5;
			font-family: "MontserratBold"; }
		&-date {
			font-family: "MontserratLight";
			margin-bottom: 10px; }
		&-review {
			text-align: justify;
			font-size: 16px; }
		@media screen and (max-width: $phone-size) {
			font-size: 15px; } }
	&__img-wr {
		float: left;
		width: 140px;
		height: 140px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 20px;
		@media screen and (max-width: $tablet-small) {
			width: 120px;
			height: 120px; }
		img {
			width: 100%;
			height: 100%; } }
	.slick-slide {
		outline: 0;
		padding: 10px;
		margin: 0 25px;
		border-radius: 20px;
		@media screen and (max-width: $tablet-small) {
			margin: 0 20px; }
		@media screen and (max-width: $phone-small) {
			margin: 0;
			padding: 10px 0; } } }
