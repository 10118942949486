.main-advantages {
	background: $white;
	background: url(../images/general/team.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: left center;
	background-attachment: fixed;
	color: $black;
	padding: 50px 0;
	&__title {
		margin-bottom: 20px; }
	&__content {
		margin-bottom: 20px;
		text-align: center; }
	.container {
		position: relative;
		z-index: 5; }
	&__text {
		font-size: 16px;
		line-height: 1.5;
		text-align: justify;
		@media screen and (max-width: $tablet-small) {
			font-size: 16px; }
		@media screen and (max-width: $phone-size) {
			font-size: 15px;
			font-weight: normal; }
		@media screen and (max-width: $phone-small) {
			line-height: 1.2; } }
	&__wrapper {
		display: flex;
		flex-direction: row;
		align-items: center; }
	&__photo {
		width: 60%;
		height: 100%;
		@media screen and (max-width: 900px) {
			display: none; } }
	&__list {
		width: 50%;
		@media screen and (max-width: 900px) {
			width: 100%; } }
	&__item {
		position: relative;
		padding-left: 60px;
		line-height: 1.2;
		font-size: 16px;
		@media screen and (max-width: $tablet-small) {
			font-size: 15px;
			padding-left: 45px; }
		&:not(:last-child) {
			margin-bottom: 10px; }
		h3 {
			font-size: 18px;
			@media screen and (max-width: $tablet-small) {
				font-size: 16px; } }
		.svg-sprite-icon {
			width: 40px;
			height: 40px;
			fill: $black;
			position: absolute;
			left: 0;
			top: 2px;
			@media screen and (max-width: $tablet-small) {
				width: 35px;
				height: 35px; } } } }

