.header-sec {
	width: 100%;
	position: relative;
	&__img {
		height: 350px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;
		margin-bottom: 50px; }
	h1 {
		text-align: center;
		margin-bottom: 30px; } }
