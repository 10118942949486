.btn {
	background-color: $accent;
	color: $black;
	border-radius: 30px;
	padding: 0 25px;
	line-height: 2.5;
	text-decoration: none;
	border: 0;
	outline: none;
	cursor: pointer;
	display: inline-block;
	@include mt(.3s);
	&:hover {
		background-color: darken($accent, 10); } }
.callback-btn {
	font-size: 15px;
	position: relative;
	padding-left: 40px;
	.svg-sprite-icon {
		width: 18px;
		height: 18px;
		position: absolute;
		left: 15px;
		top: 10px; }
	@media screen and (max-width: $phone-size) {
		padding: 0;
		font-size: 0;
		width: 40px;
		height: 38px;
		display: block;
		.svg-sprite-icon {
			left: 11px;
			top: 12px; } } }
.btn-blue {
	background: $blue;
	color: $white;
	margin: 0 auto;
	&:hover {
		background: darken($blue, 10); } }
.btn-accent {
	background: $accent;
	color: $black;
	margin: 0 auto;
	&:hover {
		background: darken($accent, 10); } }
