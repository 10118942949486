.popup {
	position: relative;
	max-width: 400px;
	margin: 30px auto;
	&__title {
		font-size: 16px;
		line-height: 1.5;
		margin: 0 auto; }
	&__header {
		padding: 20px 30px 20px 40px;
		display: flex;
		align-items: center;
		background-color: $blue;
		color: $white; }
	&__close {
		width: 25px;
		height: 25px;
		cursor: pointer;
		margin-left: auto;
		.svg-sprite-icon {
			width: 16px;
			height: 16px;
			fill: $white;
			opacity: .5;
			@include mt(.3s);
			&:hover {
				opacity: 0.8; } } }
	&__body {
		padding: 20px 40px;
		background-color: #f5f5f5; }
	.show-on-success {
		position: relative;
		text-align: center;
		margin-left: -15px;
		display: none;
		box-shadow: 0 0 4px 0 $success-color;
		color: $success-color;
		padding: 30px 20px;
		font-size: 20px;
		line-height: 1.5;
		&__title {
			display: block; } } }
