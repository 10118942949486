.footer {
	flex: 0 0 auto;
	background-color: $footer-bg;
	color: $white;
	font-size: 16px;
	&__top {
		border-bottom: 1px solid rgba($white, .3);
		padding: 20px 0;
		.container {
			display: flex;
			align-items: center;
			flex-wrap: wrap; } }
	&__socials {
		display: flex;
		flex-direction: row;
		.svg-sprite-icon {
			width: 30px;
			height: 30px;
			margin-left: 10px; } }
	&__bottom {
		border-top: 1px solid rgba($white, .3);
		padding: 20px 0;
		font-size: 16px;
		text-align: center;
		line-height: 1.5; }
	&__main {
		padding: 30px 0 10px;
		.container:first-child {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			.nav {
				margin-bottom:  20px;
				@media screen and (max-width: $tablet-size) {
					display: block; }
				&__list {
					display: flex;
					flex-direction: column;
					text-decoration: none;
					padding: 0 10px; }
				&__item {
					line-height: 1.5;
					margin: 0;
					font-size: 15px;
					color: darken($white, 20);
					padding: 0;
					border-bottom: 1px solid transparent;
					&:hover {
						border-color: rgba($white, .5);
						color: $white; }
					&:first-child {
						color: $white;
						border-color: rgba($white, .5);
						display: block;
						margin-bottom: 10px;
						font-size: 16px; } } } } }
	.top-btn {
		position: fixed;
		left: 40px;
		bottom: 40px;
		background: rgba($accent, .7);
		border: 0;
		z-index: 999;
		padding: 15px;
		display: none;
		outline: 0;
		cursor: pointer;
		@include mt(.3s);
		&:hover {
			background: $accent; }
		.svg-sprite-icon {
			width: 20px;
			height: 20px;
			fill: #000; } } }

