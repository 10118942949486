.contacts {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	@media screen and (max-width: 900px) {
		flex-direction: column;
		max-height: 800px; }
	&__img {
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		height: 400px;
		@media screen and (max-width: 900px) {
			height: 100%; } }
	&__content {
		width: 100%;
		height: 100%;
		background-color: $black-bg;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding: 50px 20px;
		&-title {
			color: $white;
			@media screen and (max-width: $phone-small) {
				font-size: 16px; } }
		&-contacts, &-time {
			font-size: 18px;
			color: $blue;
			line-height: 2;
			@media screen and (max-width: $phone-small) {
				line-height: 1.5;
				font-size: 16px; } }
		.contacts__list {
			&:last-child {
				text-align: center; }
			.contacts__item {
				color: $white;
				line-height: 1.8;
				@media screen and (max-width: $tablet-small) {
					font-size: 16px;
					line-height: 1.4; }
				@media screen and (max-width: $phone-small) {
					font-size: 14px;
					text-align: center;
					line-height: 1.2; }
				a {
					color: $white; }
				.svg-sprite-icon {
					width: 15px;
					height: 15px;
					fill: $blue;
					margin-right: 10px;
					@media screen and (max-width: $phone-small) {
						display: none; } } } } }
	&__map {
		width: 100%;
		height: 100%;
		iframe {
			width: 100%;
			height: 400px;
			border: 0; } } }
