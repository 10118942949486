@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";

html {
	height: 100%;
	font-size: $base-font-size;
	font-family: $base-font-family; }

body {
	height: 100%; }

h1, h2, h3, h4, h5, h6 {
	margin: 0; }

ul, li {
	list-style-type: none;
	margin: 0;
	padding: 0; }

a {
	text-decoration: none; }

p {
	margin: 0; }

audio,
canvas,
iframe,
img,
svg,
video,
textarea {
	vertical-align: middle; }

textarea {
	resize: none; }

section {
	position: relative; }

input,
select,
button {
	outline: none; }

*, *:before, *:after {
	box-sizing: border-box; }

.wrapper {
	display: flex;
	flex-direction: column;
	height: 100%;
	position: relative; }

.content {
	flex: 1 0 auto; }

.container {
	margin: 0 auto;
	max-width: $content-size;
	width: 100%;
	padding: 0 40px;
	@media screen and (max-width: $phone-size) {
		padding: 0 20px; } }
::placeholder {
	color: #999;
	font-size: 0.9rem; }
.red {
	color: $red; }
blockquote {
	margin: 0;
	padding: 0; }
//slider
.slick-dots {
		text-align: center;
		margin-top: 20px;
		li {
			display: inline-block;
			&:not(:last-child) {
				margin-right: 20px; }
			button {
				border-radius: 50%;
				font-size: 0;
				border: 1px solid $black;
				padding: 4px;
				cursor: pointer;
				background-color: transparent; }
			&.slick-active {
				button {
					background-color: $black;
					padding: 4px; } } } }
.slider-wrap {
	margin: 0 20px;
	position: relative; }
.slider__pagination {
	li {
		background: rgba($accent, .7);
		display: inline-block;
		padding: 10px;
		cursor: pointer;
		position: absolute;
		z-index: 998;
		top: 33%;
		@include mt(.3s);
		&.prev {
			left: 0; }
		&.next {
			right: 0; }
		.svg-sprite-icon {
			width: 20px;
			height: 20px;
			fill: #000; }
		&:hover {
			background: $accent; } } }
//Модули
@import "modules/modules";
