.main-services {
	background: $blue;
	background: url(../images/general/services-bg.jpg);
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;
	background-attachment: fixed;
	display: flex;
	margin-top: 50px;
	@media screen and (max-width: $tablet-small) {
		flex-direction: column; }
	.slider-wrap {
		margin: 0; }
	&__wrap {
		position: relative;
		z-index: 999; }
	&__content {
		width: 100%;
		.icon-logo {
			display: block;
			margin: 0 auto;
			width: 150px;
			height: 63px;
			fill: $white;
			margin-bottom: 18px;
			@media screen and (max-width: $phone-size) {
				margin-bottom: 0; } } }
	&__title {
		text-align: center;
		font-weight: normal;
		font-size: 23px;
		line-height: 1.2;
		@media screen and (max-width: $phone-size) {
			font-size: 20px; } }
	&__text {
		display: block;
		color: $white;
		text-align: center;
		font-size: 22px;
		@media screen and (max-width: $phone-size) {
			font-size: 18px; }
		&:after {
			content: '';
			width: 140px;
			height: 1px;
			display: block;
			background-color: $white;
			margin: 20px auto; } }
	&__left, &__right {
		width: 50%;
		color: $white;
		padding: 40px 20px;
		position: relative;
		@media screen and (max-width: $tablet-size) {
			padding: 20px; }
		@media screen and (max-width: $tablet-small) {
			width: 100%; }
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: rgba($black, .6);
			z-index: 1; }
		.services__list {
			column-count: 3;
			@media screen and (max-width: $tablet-size) {
				column-count: 2; }
			@media screen and (max-width: $tablet-small) {
				column-count: 3; }
			@media screen and (max-width: $phone-size) {
				column-count: 2; }
			@media screen and (max-width: $phone-small) {
				column-count: 1;
				text-align: center; }
			li {
				page-break-inside: avoid;
				break-inside: avoid;
				line-height: 1.8;
				position: relative;
				color: $white;
				padding: 5px 0;
				font-size: 15px;
				@include mt(.3s);
				&:before {
					content: '\2714';
					margin-right: 5px; }
				@media screen and (max-width: $phone-size) {
					line-height: 1.5; }
				&:hover {
					transform: translateX(20px); } } } }
	&__right {
		&:after {
			background: rgba(#069290, .7); }
		.icon-logo {
			@media screen and (max-width: $phone-size) {
				display: none; } } } }
.services-slider {
	margin-top: 30px;
	&__item {
		outline: none;
		text-align: center;
		.svg-sprite-icon {
			width: 110px;
			height: 110px;
			margin-bottom: 20px; } }
	&__text {
		text-align: center;
		color: $white;
		margin-bottom: 20px; } }
