.header {
	position: absolute;
	width: 100%;
	top: 0;
	left: 0;
	background: linear-gradient(180deg, rgba($black, .7), rgba($black, 0));
	z-index: 2;
	&-top {
		padding: 25px 0;
		border-bottom: 1px solid rgba($white, .3);
		position: relative;
		z-index: 2;
		@media screen and (max-width: $phone-size) {
			padding: 15px 0; }
		.container {
			display: flex;
			flex-direction: row;
			align-items: center;
			z-index: 2;
			.callback {
				margin-left: auto; } } }
	&-main {
		padding: 30px 0;
		position: relative;
		@media screen and (max-width: $tablet-small) {
			padding: 15px 0; }
		&__logo {
			@media screen and (max-width: $tablet-small) {
				position: absolute;
				top: -85px; }
			@media screen and (max-width: $phone-size) {
				top: -77px; }
			.icon-logo {
				fill: $white;
				width: 180px;
				height: 76px;
				cursor: pointer;
				margin-top: -5px;
				@media screen and (max-width: $tablet-small) {
					width: 140px;
					height: 64px; }
				@media screen and (max-width: $phone-small) {
					width: 100px; } } }
		&__list {
			display: flex;
			flex-direction: column;
			font-size: 16px;
			line-height: 1.6;
			color: darken($white, 10);
			@media screen and (max-width: $tablet-small) {
				font-size: 14px;
				line-height: 1.4; }
			@media screen and (max-width: $phone-size) {
				margin-bottom: 10px; }
			.svg-sprite-icon {
				width: 13px;
				height: 13px;
				fill: darken($white, 10);
				margin-right: 5px;
				@media screen and (max-width: $tablet-small) {
					width: 12px;
					height: 12px; } }
			&--socials {
				flex-direction: row;
				@media screen and (max-width: $phone-size) {
					margin: 0 auto 10px; }
				.header-main__item {
					margin-right: 5px;
					&:last-child {
						margin-right: 0; }
					.svg-sprite-icon {
						width: 30px;
						height: 30px;
						@media screen and (max-width: $tablet-small) {
							width: 25px;
							height: 25px; }
						@media screen and (max-width: $phone-size) {
							width: 30px;
							height: 30px; } } } } }
		&__link {
			color: darken($white, 10);
			&:hover {
				border-bottom: 1px solid darken($white, 10); } }
		.container {
			display: flex;
			justify-content: space-between;
			position: relative;
			@media screen and (max-width: $phone-size) {
				flex-direction: column-reverse;
				text-align: center; } } } }
