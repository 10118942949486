.search-tour {
	padding-bottom: 0;
	background-image: url(../images/general/search-tour-bg.jpg);
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	position: relative;
	&__wrap {
		background: $white-bg;
		padding: 50px 0; }
	@media screen and (max-width: $phone-size) {
		text-align: center; }
	.container {
		position: relative;
		z-index: 999;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center; }
	&__content {
		width: 100%;
		font-size: 18px;
		line-height: 1.5;
		padding-right: 30px;
		@media screen and (max-width: $tablet-small) {
			font-size: 16px;
			line-height: 1.4; }
		@media screen and (max-width: $phone-size) {
			font-size: 15px;
			padding: 0; }
		@media screen and (max-width: $phone-small) {
			line-height: 1.2; } }
	&__img {
		width: 100%;
		@media screen and (max-width: $phone-size) {
			display: none; } }
	&__form {
		background: rgba($blue, .9);
		padding: 30px 0 50px;
		position: relative;
		z-index: 4;
		.form-group {
			display: flex;
			flex-direction: column;
			width: 30%;
			@media screen and (max-width: $phone-size) {
				width: 100%; }
			.input-field, .select-field {
				border: 1px solid $blue;
				width: 100%;
				height: 40px;
				&:focus {
					border-color: darken($blue, 10); } }
			&__label {
				font-size: 16px;
				color: $white;
				width: 100%; } }
		.btn-wrap {
			margin-top: 20px;
			text-align: center; }
		.container {
			display: flex;
			flex-wrap: wrap; } } }




