.form-group {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	&--required {
		.form-group__label {
			position: relative;
			&:after {
				content: '*';
				color: $red;
				position: absolute;
				font-size: 20px;
				top: -7px;
				right: -12px; } } }
	&__label {
		font-size: 14px;
		margin: 15px 0 5px; }
	.input-field, .select-field {
		background-color: $white;
		border: 1px solid $bd-color;
		width: 100%;
		border-radius: 4px;
		line-height: 1.2;
		padding: 10px 15px;
		font-size: 14px;
		color: $black;
		&:focus {
			border-color: $input-focus; }
		&.error {
			border-color: $red;
			&::placeholder {
				color: $red; } } }
	.textarea-field {
		border: 1px solid $bd-color;
		border-radius: 4px;
		font-size: 14px;
		padding: 10px 15px;
		height: 100px;
		margin-bottom: 20px;
		width: 100%;
		outline: none;
		&:focus {
			border-color: $input-focus; } }
	.btn {
		margin: 0 auto; } }




