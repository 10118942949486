.sandwich {
	cursor: pointer;
	height: 20px;
	display: none;
	position: relative;
	width: 30px;
	margin-left: 30px;
	@media screen and (max-width: $tablet-small) {
		display: block;
		float: right; }
	@media screen and (max-width: $phone-size) {
		margin-left: 20px; }
	&__line {
		background-color: $accent;
		height: 2px;
		position: absolute;
		z-index: 999;
		@include mt(.3s);
		width: 100%;
		&--top {
			bottom: 18px; }
		&--middle {
			bottom: 9px;
			opacity: 1; }
		&--bottom {
			bottom: 0; } }
	&--active {
		z-index: 999;
		.sandwich__line {
			background-color: rgba($white, .8);
			&--top {
				bottom: 10px;
				transform: rotate(-45deg); }
			&--middle {
				opacity: 0; }
			&--bottom {
				bottom: 10px;
				transform: rotate(45deg); } } } }
