.newsletter {
	background: $blue-bg;
	padding: 50px 0;
	margin-bottom: 50px;
	.hidden {
		font-size: 20px;
		color: $white;
		display: none; }
	.container {
		display: flex;
		align-items: center;
		@media screen and (max-width: $phone-size) {
			flex-direction: column; } }
	&__content {
		display: flex;
		flex-direction: row;
		align-items: center;
		width: 100%;
		@media screen and (max-width: $phone-size) {
			margin-bottom: 20px; }
		.icon-paper-plane {
			fill: $white;
			width: 120px;
			height: 50px; }
		&-text {
			color: $white;
			font-size: 18px;
			padding: 0 20px;
			@media screen and (max-width: $tablet-small) {
				font-size: 16px; }
			@media screen and (max-width: $phone-size) {
				font-size: 15px; } } }
	&__form {
		width: 100%;
		.form-group {
			display: flex;
			flex-direction: row;
			align-content: space-between;
			@media screen and (max-width: $phone-small) {
				flex-direction: column; }
			.input-field {
				margin-right: 30px;
				@media screen and (max-width: $phone-small) {
					width: 100%;
					margin-bottom: 20px; } } } } }
