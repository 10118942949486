// Colors
$white: #ffffff;
$black: #000000;
$accent: #FFF181;
$blue: #40BDBD;
$bd-color: #ededed;
$red: #f74d3c;
$orange: #FF7F00;
$input-focus: #28c4e7;
$success-color: #66af74;
$section-bg: rgba($white, .9);
$footer-bg: #478E8E;
$white-bg: rgba($white, .9);
$blue-bg: rgba($blue, .7);
$black-bg: rgba($black, .5);
// Sizes
$base-font-size: 16px;

$content-size: 1240px;
$tablet-size: 1199px;
$tablet-small: 991px;
$phone-size: 767px;
$phone-small: 480px;

// Fonts
$decor-font-family: "Aliceregular", "Arial", serif;
$base-font-family: "HelveticaRegular", "Arial", serif;
// Animation
@mixin mt($time) {
  transition: all $time ease; }
