.main-slider {
	width: 100%;
	position: relative;
	&__item {
		position: relative;
		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: rgba($black, .3);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 2; } }
	&__img {
		height: 100vh;
		min-height: 700px;
		background-repeat: no-repeat;
		background-position: center;
		background-size: cover;
		@media screen and (max-width: $tablet-size) {
			min-height: 100%;
			height: 700px; }
		@media screen and (max-width: $phone-size) {
			height: 600px; }
		@media screen and (max-width: $phone-small) {
			height: 500px; } }
	&__content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: $white;
		z-index: 3;
		width: 100%;
		max-width: 880px;
		padding: 0 20px;
		display: flex;
		flex-direction: column;
		text-align: center;
		@media screen and (max-width: $phone-size) {
			top: 70%;
			transform: translate(-50%, -70%); }
		@media screen and (max-width: $phone-small) {
			top: 80%;
			transform: translate(-50%, -80%); }
		.content-text {
			font-size: 30px;
			display: block;
			line-height: 1.5;
			font-weight: 900;
			@media screen and (max-width: $phone-size) {
				font-size: 25px; }
			@media screen and (max-width: $phone-small) {
				font-size: 20px;
				line-height: 1.2; } }
		.content-title {
			font-size: 70px;
			font-weight: 900;
			margin-bottom: 20px;
			@media screen and (max-width: $phone-size) {
				font-size: 55px;
				margin-bottom: 0; }
			@media screen and (max-width: $phone-small) {
				font-size: 40px; } }
		.content-btn {
			margin-top: 40px;
			@media screen and (max-width: $phone-small) {
				margin-top: 20px; } }
		.slider-btn {
			font-family: $base-font-family;
			font-size: 18px;
			display: inline-block;
			font-weight: normal; } }
	.slick-dots {
		position: absolute;
		bottom: 180px;
		left: 50%;
		transform: translateX(-50%);
		@media screen and (max-width: $tablet-small) {
			bottom: 50px; }
		@media screen and (max-width: $phone-size) {
			bottom: 30px; }
		li {
			display: inline-block;
			margin-right: 20px;
			&:last-child {
				margin-right: 0; }
			button {
				font-size: 0;
				border: 1px solid rgba($white, .6);
				padding: 5px;
				border-radius: 50%;
				background-color: transparent;
				cursor: pointer;
				@media screen and (max-width: $phone-size) {
					padding: 3px; } }
			&.slick-active {
				button {
					background-color: rgba($white, .8); } } } } }
