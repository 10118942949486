.header-advantages {
	margin-top: -123px;
	border-top: solid 1px rgba($white, 0.3);
	background: rgba($black, .2);
	@media screen and (max-width: $tablet-size) {
		padding: 0 10px; }
	@media screen and (max-width: $tablet-small) {
		padding: 0;
		margin-top: 0;
		background: $blue; }
	@media screen and (max-width: $phone-size) {
		display: none; }
	&__list {
		display: flex;
		flex-direction: row;
		justify-content: space-between; }
	&__item {
		display: flex;
		flex-direction: column;
		text-align: center;
		width: 20%;
		padding: 20px 10px;
		border-right: solid 1px rgba($white, 0.3);
		&:first-child {
			border-left: solid 1px rgba($white, 0.3); }
		@media screen and (max-width: $tablet-size) {
			&:first-child {
				border-left: 0; }
			&:last-child {
				border-right: 0; } }
		@media screen and (max-width: $phone-size) {
			font-size: 16px;
			border: 0;
			width: 100%;
			border-bottom: 1px solid rgba($white, .6);
			padding: 20px; }
		.svg-sprite-icon {
			fill: $white;
			width: 40px;
			height: 40px;
			margin: 0 auto 10px; } }

	&__text {
		color: $white;
		font-size: 14px; }
	.container {
		@media screen and (max-width: $tablet-size) {
			padding: 0; } } }
