.nav {
	font-size: 16px;
	.icon-logo {
		display: none; }
	@media screen and (max-width: $tablet-small) {
		display: none; }
	&__list {
		display: flex; }
	&__item {
		margin-right: 30px;
		&:last-child {
			margin-right: 0; }
		@media screen and (max-width: $tablet-small) {
			margin-right: 20px; }
		&--dropdown {
			margin-right: 30px;
			@media screen and (max-width: $tablet-small) {
				text-align: center;
				margin-right: 0; }
			.dropdown-menu {
				display: none;
				position: absolute;
				background: $blue;
				color: $white;
				padding: 15px;
				margin-top: 10px;
				@include mt(.3s);
				&__item {
					&:last-child {
						border-bottom: 0; } }
				&__link {
					color: $white;
					display: inline-block;
					text-decoration: none;
					line-height: 1.6;
					padding: 5px 30px;
					width: 100%;
					@include mt(.3s);
					&:hover {
						background: lighten($blue, 7); }
					@media screen and (max-width: $tablet-small) {
						padding: 5px 20px; } } }
			&:hover {
				.dropdown-menu {
					display: block;
					@media screen and (max-width: $tablet-small) {
						display: none; } } } } }
	&__link {
		color: $white;
		text-decoration: none;
		padding-bottom: 10px;
		border-bottom: 1px solid transparent;
		@include mt(.3s);
		&--triangle {
			position: relative;
			padding-right: 15px;
			color: $white;
			padding-bottom: 10px;
			&:after {
				content: '';
				position: absolute;
				right: 0;
				top: 8px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 4px 5px 0 5px;
				border-color: $white transparent transparent transparent; } }
		&:hover, &.active {
			border-bottom: 1px solid $blue; } } }
.nav-mobile {
	padding: 20px 20px 50px;
	display: block;
	background: $blue;
	position: absolute;
	z-index: 999;
	width: 100%;
	left: 0;
	top: 0;
	.icon-logo {
		display: block;
		width: 120px;
		height: 53px;
		fill: $white;
		margin: 0 auto 20px; }
	.nav__list {
		@media screen and (max-width: $tablet-small) {
			flex-direction: column; } }
	.nav__item {
		border-bottom: 1px solid rgba($white, .5);
		&:first-child {
			border-top: 1px solid rgba($white, .5); }
		&--dropdown {
			border-bottom: 1px solid rgba($white, .5); }
		@media screen and (max-width: $tablet-small) {
			text-align: center;
			margin: 0; } }
	.nav__link {
		display: block;
		line-height: 2.5;
		padding: 0;
		color: darken($white, 10);
		&--triangle {
			padding: 5px 30px;
			line-height: 2.5;
			color: darken($white, 10);
			&:hover, &.active {
				color: $white; }
			&:after {
				display: none; } }
		@media screen and (max-width: $tablet-small) {
			border: 0;
			&:hover, &.active {
				color: $white; } } } }
