.tours {
	padding-top: 50px;
	.container {
		position: relative; }
	.tour-btn {
		text-align: center; }
	&__btn {
		background-color: $white;
		border: 1px solid #5b5e60;
		padding: 10px;
		border-radius: 4px;
		cursor: pointer;
		color: #5b5e60;
		margin-bottom: 20px;
		@include mt(.3s);
		@media screen and (max-width: $phone-size) {
			display: flex;
			flex-direction: column;
			margin-bottom: 10px; }
		@media screen and (max-width: $phone-small) {
			margin: 0 auto 10px; }
		&:hover {
			background-color: darken($white, 5);
			color: $black; }
		&.active {
			background-color: $blue;
			border-color: transparent;
			color: $white; } } }
.js-tours__slider, .js-special-tours__slider {
	margin: 0 10px; }
.tours {
	&__item {
		width: 100%;
		height: 100%;
		margin: 0 10px;
		overflow: hidden;
		outline: none;
		.svg-sprite-icon {
			width: 30px;
			height: 30px;
			position: absolute;
			left: 10px;
			top: 10px; } }
	.img {
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;
		width: 100%;
		height: 280px;
		position: relative;
		margin-bottom: 20px; }
	.content {
		padding: 20px;
		position: absolute;
		background-color: $black-bg;
		width: 100%;
		height: auto;
		color: $white;
		z-index: 3;
		bottom: 0;
		left: 0;
		@include mt(.5s);
		&__title {
			font-size: 25px; }
		&__text {
			font-size: 15px;
			font-weight: 300;
			display: block; } }
	.price {
			position: absolute;
			top: 10px;
			right: 10px;
			background: $red;
			color: $white;
			padding: 5px;
			border-radius: 5px; }
	.btn__wrap {
		text-align: center;
		margin-top: 10px; }
	.hot, .booking, .hit {
		.price {
			background: $red;
			cursor: pointer; }
		.icon-fire {
			fill: $red; } }
	.bus, .bus-sea, .hot, .booking, .hit {
		cursor: pointer;
		&:hover {
			.content.visible {
				opacity: 0;
				visibility: hidden; }
			.hidden {
				visibility: visible;
				opacity: 1; } }
		.price {
			background: $accent;
			color: $black;
			cursor: pointer; }
		.icon-bus {
			fill: $accent; }
		.hidden {
			visibility: hidden;
			opacity: 0; } } }
.tours-page {
	h1 {
		font-size: 25px;
		text-align: center;
		margin-bottom: 30px; }
	.tours {
		margin: 0 0 50px;
		padding-top: 0;
		.container {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			justify-content: space-around;
			overflow: hidden;
			.tours__item {
				width: 30%;
				margin-bottom: 20px;
				@media screen and (max-width: 900px) {
					width: 45%; }
				@media screen and (max-width: 576px) {
					width: 100%; } } } }
	.mixitup-control-active {
		background-color: $blue;
		color: $white;
		border-color: $blue; } }
